.videoContainer {
  position: relative;
}

video {
  width: 100vw;
  height: 100vh;
  /* object-fit: fill; */
  object-fit: contain;
  /* object-fit: cover; */
  position: absolute;
  top: 0;
  left: 0;
}

/* .videoButton1 {
  position: absolute;
  top: 10;
  left: 10;
}

.videoButton2 {
  position: absolute;
  top: 10;
  right: 10;
} */